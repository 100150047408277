<template>
	<div style="background: #f9f9fb;height: 100vh;" v-if="dataInfo!==null">
		<div style="background: #fff;padding: 20px;">
			<div style="display: flex;align-items: center;position: relative;">
				<a-icon type="car" theme="twoTone" :style="iconStyle" />
				<div style="padding: 10px 0px 10px 0;width: 100%;border-bottom: 1px solid #f3f3f3;margin-left: 20px;color: #1890ff;">订单还未发货，暂无物流信息</div>
				<div style="position: absolute;right:0;">
					<a-icon type="right" :style="iconStyle2"/>
				</div>
			</div>
			<div style="display: flex;align-items: center;position: relative;">
				<el-image :src="require('@/assets/img/icon/address.png')" style="width: 25px;height:25px;"></el-image>
				<div style="padding: 10px 0px 10px 0;width: 100%;margin-left: 20px;">{{dataInfo!==null ? dataInfo.address : ''}}</div>
			</div>
		</div>
		
		<div style="margin: 10px 0;background: #fff;padding: 20px;">
			<div style="width: 100%;position: relative;padding-bottom: 10px;border-bottom: 1px solid #f3f3f3;">订单状态
				<!-- <div style="position: absolute;right: 0;top: 0;" :style="dataInfo.orderstatus==0 ? 'color:orange' : dataInfo.orderstatus==1 ? 'color:orange' : dataInfo.orderstatus == 2 ? 'color:red' : dataInfo.orderstatus == 3 ? 'color:blue' : dataInfo.orderstatus == 4 ? 'color:green' : 'color:purple'">
					{{translate(1,dataInfo.orderstatus)}}<a-icon type="right" style="padding-left: 10px;" :style="iconStyle2"/></div> -->
				<div style="position: absolute;right: 0;top: 0;" :style="dataInfo.orderstatus==0 ? 'color:orange' : dataInfo.orderstatus==1 ? 'color:orange' : dataInfo.orderstatus == 2 ? 'color:red' : dataInfo.orderstatus == 3 ? 'color:blue' : dataInfo.orderstatus == 4 ? 'color:green' : 'color:purple'">
					{{translate(1,dataInfo.orderstatus)}}</div>
			</div>
			
			<div  style="padding: 10px 0 0 0;position: relative;" @click="detailOpen">
				<div style="position: relative;">
					<div style="margin-right: 20px;" v-if="value==1">
						{{dataInfo.pcbInfoList[0].pcblength}}x{{dataInfo.pcbInfoList[0].pcbwidth}}cm,{{dataList.plateTG[dataInfo.pcbInfoList[0].pcbbrand-1].name}},
						{{dataList.numberBoardLayers[dataInfo.pcbInfoList[0].pcblayer-1].name}},{{dataList.minimumThroughHole[dataInfo.pcbInfoList[0].pcbminhole-1].name}},
						{{dataList.resistanceWeldingColor[dataInfo.pcbInfoList[0].pcbholecolor-1].name}},{{dataList.solderPadSprayCoating[dataInfo.pcbInfoList[0].pcbholespray-1].name}},
						{{dataList.FPCCopperThickness[dataInfo.pcbInfoList[0].pcbcuthick-1].name}}
					</div>
					
					<div style="margin-right: 20px;" v-if="value==2">
						pcb数量:{{dataInfo.smtboardcount}},元器件数量:{{dataInfo.pcbInfoList[0].smtcompcount}},{{dataInfo.pcbInfoList[0].smttechreq ? dataList.SMTProcessRequirements[dataInfo.pcbInfoList[0].smttechreq-1].name + ',' : ''}}{{dataInfo.smtisbake==1?'需要烘焙':'不需要烘焙'}},
						{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}
					</div>
					
					<div style="margin-right: 20px;" v-if="value==3">
						钢网数量:{{dataInfo.stencount}},{{dataList.steelMeshType[dataInfo.pcbInfoList[0].stentype-1].name}},{{dataInfo.stentype==1 ? dataList.steelMeshSpecifications1[dataInfo.stenspec-1].name : dataInfo.pcbInfoList[0].stentype==2 ? dataList.steelMeshSpecifications2[dataInfo.pcbInfoList[0].stenspec-1].name : dataList.steelMeshSpecifications3[dataInfo.pcbInfoList[0].stenspec-1].name}},
						{{dataList.steelMeshThickness[dataInfo.pcbInfoList[0].stenthick-1].name}},{{dataList.steelMeshProductionMethod[dataInfo.pcbInfoList[0].stenway-1].name}},{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}
					</div>
					
					<div style="margin-right: 20px;" v-if="value==4">
						文件名:{{dataInfo.filename}}
					</div>
					
					<div style="color:#bfbfbf;">订单编号：{{dataInfo.orderno}}</div>
					<div style="color:#bfbfbf;">数量：{{value == 4 ? dataInfo.purchaseamount : dataInfo.pcbcount}}</div>
					<div style="position: absolute;right:0;height: 100%;top: 0;display: flex;align-items: center;">
						<a-icon type="right" :style="iconStyle2"/>
					</div>
				</div>
			</div>
		</div>
		
		<div style="margin-bottom: 10px;padding: 20px;background: #fff;position: relative;">
			<div style="padding-bottom: 10px;border-bottom: 1px solid #f3f3f3;width: 100%;">发票信息</div>
			<div style="position: absolute;top: 20px;right: 20px;">{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}</div>
			<div style="padding-top: 10px;position: relative;">
				<div>选择快递</div>
				<div style="position: absolute;top:10px;right: 0;">顺丰电商标快</div>
			</div>
		</div>
		
		<div style="margin-bottom: 10px;padding: 20px;background: #fff;position: relative;" @click="priceOpen">
			<div style="">订单金额</div>
			<div style="position: absolute;right: 20px;top: 20px;color: red;" v-if="value==1">￥{{dataInfo.pcbprice}}<a-icon type="right" style="padding-left: 10px;" :style="iconStyle2"/></div>
			<div style="position: absolute;right: 20px;top: 20px;color: red;" v-if="value==2">￥{{dataInfo.smtfee}}<a-icon type="right" style="padding-left: 10px;" :style="iconStyle2"/></div>
			<div style="position: absolute;right: 20px;top: 20px;color: red;" v-if="value==3">￥{{dataInfo.stentfee}}<a-icon type="right" style="padding-left: 10px;" :style="iconStyle2"/></div>
			<div style="position: absolute;right: 20px;top: 20px;color: red;" v-if="value==4">审核后报价<a-icon type="right" style="padding-left: 10px;" :style="iconStyle2"/></div>
		</div>
		
		<div style="padding: 20px;background: #fff;position: relative;">
			<div style="padding-bottom: 10px;border-bottom: 1px solid #f3f3f3;width: 100%;">备注：</div>
			<div style="padding-top: 10px;">下单时间：{{dataInfo.createdate}}</div>
		</div>
		
		<el-drawer
		  title="订单明细"
		  :visible.sync="drawer"
		  size="70%"
		  :direction="direction">
		  <div style="padding-bottom: 80px;" v-if="value==1">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">订单编号：</div>
			  	  <div style="width: 50%;">{{dataInfo.orderno}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">下单时间：</div>
			  	  <div style="width: 50%;">{{dataInfo.createdate}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板子长宽：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].pcblength}}x{{dataInfo.pcbInfoList[0].pcbwidth}}cm</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板子数量：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].pcbcount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板子面积：</div>
			  	  <div style="width: 50%;">{{dataList.minimumThroughHole[dataInfo.pcbInfoList[0].pcbminhole-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板子层数：</div>
			  	  <div style="width: 50%;">{{dataList.numberBoardLayers[dataInfo.pcbInfoList[0].pcblayer-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">设计文件数：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].pcbdesigncount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">阻焊颜色：</div>
			  	  <div style="width: 50%;">{{dataList.resistanceWeldingColor[dataInfo.pcbInfoList[0].pcbholecolor-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">字符颜色：</div>
			  	  <div style="width: 50%;">{{dataList.characterColor[dataInfo.pcbInfoList[0].pcbfontcolor-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板子厚度：</div>
			  	  <div style="width: 50%;">{{dataList.boardThickness[dataInfo.pcbInfoList[0].pcbcuthick-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">最小线宽/线距：</div>
			  	  <div style="width: 50%;">{{dataList.minimumLineWidth[dataInfo.pcbInfoList[0].pcbminlinewidth-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">过孔：</div>
			  	  <div style="width: 50%;">{{dataList.minimumThroughHole[dataInfo.pcbInfoList[0].pcbminhole-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">铜箔厚度：</div>
			  	  <div style="width: 50%;">{{dataList.copperThickness[dataInfo.pcbInfoList[0].pcbcuthick-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">飞针测试：</div>
			  	  <div style="width: 50%;">{{dataList.text[dataInfo.pcbInfoList[0].pcbtestmethod-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">焊盘喷镀：</div>
			  	  <div style="width: 50%;">{{dataList.solderPadSprayCoating[dataInfo.pcbInfoList[0].pcbholespray-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">阻焊覆盖：</div>
			  	  <div style="width: 50%;">{{dataList.resistanceWeldingCoverage[dataInfo.pcbInfoList[0].pcbweldcover-1].name}}</div>
			  </div>
			  <!-- <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">交货日期：</div>
			  	  <div style="width: 50%;"></div>
			  </div> -->
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">发票类型：</div>
			  	  <div style="width: 50%;">{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}</div>
			  </div>
		  </div>
		  
		  <div style="padding-bottom: 80px;" v-if="value==2">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">订单编号：</div>
			  	  <div style="width: 50%;">{{dataInfo.orderno}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">下单时间：</div>
			  	  <div style="width: 50%;">{{filterTime(dataInfo.pcbInfoList[0].createdate)}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">元器件数量：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].smtcompcount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">元器件数量：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].smtcompcount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">电路板数量：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].smtboardcount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">工艺需求：</div>
			  	  <div style="width: 50%;">{{dataList.SMTProcessRequirements[dataInfo.pcbInfoList[0].smttechreq-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">是否开钢网：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].smtissten==1?'是':'否'}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">代购元件：</div>
			  	  <div style="width: 50%;">{{dataList.purchaseOnBehalf[dataInfo.pcbInfoList[0].smtagentcomp-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">是否喷三防漆：</div>
			  	  <div style="width: 50%;">{{dataList.conformalCoating[dataInfo.pcbInfoList[0].smtislac-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">元器件是否需要烘烤：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].smtisbake==1?'是':'否'}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">元器件烘焙时长：</div>
			  	  <div style="width: 50%;">{{dataList.bakingTime[dataInfo.pcbInfoList[0].smtbakedate-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">其他需求：</div>
			  	  <div style="width: 50%;"></div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">发票类型：</div>
			  	  <div style="width: 50%;">{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}</div>
			  </div>
		  </div>
		  
		  <div style="padding-bottom: 80px;" v-if="value==3">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">订单编号：</div>
			  	  <div style="width: 50%;">{{dataInfo.orderno}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">下单时间：</div>
			  	  <div style="width: 50%;">{{filterTime(dataInfo.pcbInfoList[0].createdate)}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">钢网类型：</div>
			  	  <div style="width: 50%;">{{dataList.steelMeshType[dataInfo.pcbInfoList[0].stentype-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">钢网规格：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].stentype==1 ? dataList.steelMeshSpecifications1[dataInfo.pcbInfoList[0].stenspec-1].name : dataInfo.pcbInfoList[0].stentype==2 ? dataList.steelMeshSpecifications2[dataInfo.pcbInfoList[0].stenspec-1].name : dataList.steelMeshSpecifications3[dataInfo.pcbInfoList[0].stenspec-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">纳米处理：</div>
			  	  <div style="width: 50%;">{{list1[dataInfo.pcbInfoList[0].stennanometer-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">制作方式：</div>
			  	  <div style="width: 50%;">{{dataList.steelMeshProductionMethod[dataInfo.pcbInfoList[0].stenway-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">钢网数量：</div>
			  	  <div style="width: 50%;">{{dataInfo.pcbInfoList[0].stencount}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">MARK需求：</div>
			  	  <div style="width: 50%;">{{dataList.MARKneed[dataInfo.pcbInfoList[0].stenmark-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">钢网用途：</div>
			  	  <div style="width: 50%;">{{dataList.steelMeshUsage[dataInfo.pcbInfoList[0].stenuse-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">厚度：</div>
			  	  <div style="width: 50%;">{{dataList.steelMeshThickness[dataInfo.pcbInfoList[0].stenthick-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">抛光工艺：</div>
			  	  <div style="width: 50%;">{{dataList.polishingProcess[dataInfo.pcbInfoList[0].stentechart-1].name}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">其他需求：</div>
			  	  <div style="width: 50%;"></div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">发票类型：</div>
			  	  <div style="width: 50%;">{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}</div>
			  </div>
		  </div>
		  
		  <div style="padding-bottom: 80px;" v-if="value==4">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">订单编号：</div>
			  	  <div style="width: 50%;">{{dataInfo.orderno}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">下单时间：</div>
			  	  <div style="width: 50%;">{{filterTime(dataInfo.pcbInfoList[0].createdate)}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">文件名：</div>
			  	  <div style="width: 50%;">{{dataInfo.filename}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">发票类型：</div>
			  	  <div style="width: 50%;">{{dataList.invoicingMethod[dataInfo.invoicetype-1].name}}</div>
			  </div>
		  </div>
		</el-drawer>
		
		<el-drawer
		  title="费用明细"
		  :visible.sync="priceDrawer"
		  size="40%"
		  :direction="direction">
		  <div v-if="value==1">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">合计：</div>
			  	  <div style="width: 50%;">{{dataInfo.totalprice}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">工程费：</div>
			  	  <div style="width: 50%;">{{dataInfo.engineerfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">板费：</div>
			  	  <div style="width: 50%;">{{dataInfo.boardfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">菲林费：</div>
			  	  <div style="width: 50%;">{{dataInfo.filmfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">喷镀费：</div>
			  	  <div style="width: 50%;">{{dataInfo.sprayfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">颜色费：</div>
			  	  <div style="width: 50%;">{{dataInfo.colorfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">测试费：</div>
			  	  <div style="width: 50%;">{{dataInfo.testfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">加急费：</div>
			  	  <div style="width: 50%;">{{dataInfo.urgentfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">税费：</div>
			  	  <div style="width: 50%;">{{dataInfo.taxationfee}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">其它费用：</div>
			  	  <div style="width: 50%;">{{dataInfo.otherprice}}</div>
			  </div>
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">其它费用：</div>
			  	  <div style="width: 50%;">{{dataInfo.otherprice}}</div>
			  </div>
		  </div>
		  
		  <div v-if="value==2">
			  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
			  	  <div style="width: 50%;color: #bfbfbf;">合计：</div>
			  	  <div style="width: 50%;">{{dataInfo.smtfee}}</div>
			  </div>
		  </div>
		  
		  <div v-if="value==3">
		  	  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
		  		  <div style="width: 50%;color: #bfbfbf;">合计：</div>
		  		  <div style="width: 50%;">{{dataInfo.stentfee}}</div>
		  	  </div>
		  </div>
		  
		  <div v-if="value==4">
		  	  <div style="display: flex;padding: 10px;width: 100%;border-bottom: 1px solid #f3f3f3;">
		  		  <div style="width: 50%;color: #bfbfbf;">合计：</div>
		  		  <div style="width: 50%;">审核后报价</div>
		  	  </div>
		  </div>
		  
		</el-drawer>
		<applet-bottom></applet-bottom>
	</div>
</template>

<script>
	import {
		openQuotePage,
	} from '@/api/orderManagement/index'
	import appletBottom from '@/components/bottom/appletIndex.vue';
	import {
		dataInJs
	} from '@/business/valuation/components/data.js';
	import moment from 'moment';
	export default {
		components: {
			appletBottom
		},
		data(){
			return{
				id:null,//详情id
				iconStyle:{
					fontSize:"20px"
				},
				iconStyle2:{
					fontSize:"16px",
					color:"#bcbcbc"
				},
				dataInfo:null,
				dataList: dataInJs(), //字典list数据
				tabsList:[{
					title: '全部订单',
					key: undefined
				}, {
					title: '待审核',
					key: 0
				}, {
					title: '审核中',
					key: 1
				}, {
					title: '等待付款',
					key: 2
				}, {
					title: '生产中',
					key: 3
				}, {
					title: '已完成',
					key: 4
				}, {
					title: '已取消',
					key: 5
				}],
				list1:[{
					name:"不需要",
					enName:"Not required",
					type:true
				},
				{
					name:"需要",
					enName:"Need",
					type:true
				}],
				drawer: false,//详情抽屉开关
				direction: 'btt',
				priceDrawer: false,//价格抽屉开关
				value:null,
			}
		},
		
		created() {
			// console.log(this.$route.query)
			this.id=this.$route.query.id;
			this.value = this.$route.query.value;
			this.openQuotePage();
		},
		
		mounted() {
			
		},
		
		watch: {
			
		},
		
		computed: {
		
		},
		
		methods: {
			//----------------调用详情接口-------------------------
			openQuotePage:function(){
				let data = {
					id:this.id
				}
				openQuotePage(data).then(res=>{
					this.dataInfo=res.data
				})
			},
			
			//-----------------订单状态筛选------------------------
			translate: function(e, key) {
				if (e == 1) {
					let a = null
					this.tabsList.filter(res => {
						if (key == res.key) {
							a = res.title
						}
					})
					return a
				}
			},
			
			//-------------------打开明细弹框------------------------
			detailOpen:function(){
				this.drawer=true;
			},
			
			//-------------------打开价格明细弹框------------------------
			priceOpen:function(){
				this.priceDrawer=true;
			},
			
			//------------------时间戳翻译-----------------------
			filterTime(time) {
				return moment(time).format("YYYY-MM-DD HH:mm:ss")
			},
		}
	}
</script>

<style lang="less" scoped>
	
</style>